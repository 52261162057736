import React from "react";
import styled from "styled-components";
import CapabilitiesSection from "./capabilitiesSection";
import CaseStudy from "./caseStudy";
import Testimonial from "./testimonial";

const PortfolioWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* padding-top: 40px; */
`;

function Portfolio() {
  return (
    <PortfolioWrapper id="#portfolio">
      <CaseStudy />
      <Testimonial />
      <CapabilitiesSection />
    </PortfolioWrapper>
  );
}

export default Portfolio;
