const orangeColor = "#FF5300";
const blackColor = "#373737";
const grayColor = "#7B7B7B";
const whiteColor = "#FFFFFF";
const orangeBackground = "#FFF7F4";
const darkBackground = "#18191A";
const darkView1 = "#242526";
const darkView2 = "#3A3B3C";

export {
  orangeColor,
  blackColor,
  grayColor,
  whiteColor,
  orangeBackground,
  darkBackground,
  darkView1,
  darkView2,
};
