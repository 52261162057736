import React from "react";
import styled from "styled-components";
import { services } from "../data/tempData";
import { blackColor, fontSizes, grayColor } from "../theme";

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  max-height: 300px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  /* background-color: green; */
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;

      img {
        height: 60px;
        width: 60px;
      }

      h4 {
        font-size: ${fontSizes.desktop.h5};
        font-weight: 600;
        color: ${blackColor};
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        font-weight: 300;
        color: ${grayColor};
        font-size: ${fontSizes.desktop.h7};
      }
    }

    .index {
      font-weight: 600;
      color: #efefef;
      font-size: 64px;
    }
  }
  .desc {
    font-weight: 400;
    color: ${grayColor};
    font-size: ${fontSizes.desktop.h6};
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

function Service({ title, subTitle, description, index, icon }) {
  return (
    <ServiceWrapper>
      <div>
        <div>
          <img src={services[index - 1].icon} alt="icon" />
          <h4>{title}</h4>
          <p>{subTitle}</p>
        </div>
        <p className="index">0{index}</p>
      </div>
      <p className="desc">{description}</p>
    </ServiceWrapper>
  );
}

export default Service;
