import React from "react";
import styled from "styled-components";
import { fontSizes, orangeColor } from "../theme";

const TitleHeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;

  span {
    height: 1.5px;
    width: 50px;
    background-color: ${orangeColor};
  }
  p {
    justify-self: center;
    color: ${orangeColor};
    font-size: ${fontSizes.desktop.h6};
  }
`;

function TitleHeader({ title }) {
  return (
    <TitleHeaderWrapper>
      <span></span>
      <p>{title}</p>
      <span></span>
    </TitleHeaderWrapper>
  );
}

export default TitleHeader;
