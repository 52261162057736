import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useContext } from "react";
import SnackbarContext from "../context/snackbarContext";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackbarComponent() {
  const { open, setOpen } = useContext(SnackbarContext);
  console.log(open);
  return (
    <Snackbar
      style={{
        width: "95vw",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
    >
      <Alert
        onClose={() => setOpen(false)}
        severity="success"
        sx={{ width: "100%" }}
      >
        Data submitted successfully
      </Alert>
    </Snackbar>
  );
}

export default SnackbarComponent;
