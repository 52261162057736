const fontSizes = {
  desktop: {
    h1: "48px",
    h2: "39.06px",
    h3: "31.25px",
    h4: "25px",
    h5: "20px",
    h6: "16px",
    h7: "12.8px",
  },
  tablet: {
    h1: "39.06px",
    h2: "31.25px",
    h3: "25px",
    h4: "20px",
    h5: "16px",
    h6: "12.8px",
  },
  mobile: {
    h1: "31.25px",
    h2: "25px",
    h3: "20px",
    h4: "16px",
    h5: "12.8px",
  },
};

export default fontSizes;
