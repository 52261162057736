import styled from "styled-components";
import { orangeColor } from "../theme";

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 30px;
    height: 30px;
    border: 2px solid #fff; /* Light grey */
    border-top: 5px solid ${orangeColor};
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
`;

export default function LoadingSpinner() {
  return (
    <LoadingSpinnerWrapper>
      <div className="loading-spinner"></div>
    </LoadingSpinnerWrapper>
  );
}
