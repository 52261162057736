import { createContext, useState } from "react";

const SnackbarContext = createContext(false);
export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <SnackbarContext.Provider value={{ open, setOpen }}>
      {children}
    </SnackbarContext.Provider>
  );
};
export default SnackbarContext;
