import { DataStore } from "aws-amplify";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../constants";
import SnackbarContext from "../context/snackbarContext";
import { Contact as ContactModel } from "../models";
import { fontSizes, orangeBackground, orangeColor } from "../theme";
import LoadingSpinner from "./loadingSpinner";

const ContactWrapper = styled.section`
  background-color: ${orangeBackground};
  min-height: 100vh;
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 10px;
    div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      p {
        padding-left: 20px;
        color: #667299;
        font-family: "Work Sans", sans-serif;
        @media only screen and (max-width: ${breakpoints.tablet}) {
          padding-left: center;
        }
        span {
          color: ${orangeColor};
          font-family: "Work Sans", sans-serif;
        }
      }
      @media only screen and (max-width: ${breakpoints.tablet}) {
        justify-content: center;
      }
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 10px;
      align-items: center;
    }
  }

  h2 {
    font-weight: 700;
    color: #292e3d;
    font-size: ${fontSizes.desktop.h2};
    @media only screen and (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSizes.tablet.h2};
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mobile.h2};
    }
  }

  p.form-desc {
    max-width: 500px;
    text-align: center;
    color: #292e3d;
    font-weight: 400;
    font-size: ${fontSizes.desktop.h6};
  }

  input {
    border-radius: 4px;
    padding: 4px;
    min-width: 250px;
    border: 1px #e0e3eb solid;
    outline: none;
    font-family: "Work Sans", sans-serif;
    &::placeholder {
      color: #a3aac2;
      font-weight: 400;
      font-size: 15px;
      padding: 4px;
    }
  }

  input[type="submit"] {
    background-color: ${orangeColor};
    color: #fff;
    cursor: pointer;
    width: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;
    align-self: center;
    margin-top: 10px;
  }

  textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px #e0e3eb solid;
    outline: none;
    padding: 4px;
    font-family: "Work Sans", sans-serif;
    &::placeholder {
      color: #a3aac2;
      font-weight: 400;
      font-size: 15px;
      /* padding: 4px; */
    }
  }
`;

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const { setOpen } = useContext(SnackbarContext);
  const [data, setData] = useState({
    email: "",
    name: "",
    reason: "",
    phone: "",
    message: "",
  });

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await DataStore.save(new ContactModel(data));
      setData({
        email: "",
        name: "",
        reason: "",
        phone: "",
        message: "",
      });
      setIsLoading(false);
      setOpen(true);
    } catch (error) {
      alert("Error sending data");
      setIsLoading(false);
    }
  };
  return (
    <ContactWrapper id="#contact">
      <h2>What can we do for you?</h2>
      <p className="form-desc">
        We are ready to work on a project of any complexity, whether it’s
        commercial or residential.
      </p>
      <form method="post" onSubmit={onFormSubmit}>
        <div className="group">
          <input
            placeholder="Your name*"
            type="text"
            name="name"
            id="name"
            value={data.name}
            onChange={(e) => {
              setData((prev) => ({ ...prev, "name": e.target.value }));
            }}
            required
          />
          <input
            placeholder="Email*"
            type="email"
            name="email"
            id="email"
            required
            value={data.email}
            onChange={(e) => {
              setData((prev) => ({ ...prev, "email": e.target.value }));
            }}
          />
        </div>
        <div className="group">
          <input
            type="text"
            placeholder="Reason for Contacting*"
            name="reason"
            id="reason"
            value={data.reason}
            required
            onChange={(e) => {
              setData((prev) => ({ ...prev, "reason": e.target.value }));
            }}
          />
          <input
            value={data.phone}
            type="tel"
            placeholder="Phone"
            name="phone"
            id="phone"
            onChange={(e) => {
              setData((prev) => ({ ...prev, "phone": e.target.value }));
            }}
          />
        </div>
        <textarea
          value={data.message}
          placeholder="Message"
          name="message"
          id="message"
          cols="30"
          rows="10"
          onChange={(e) => {
            setData((prev) => ({ ...prev, "message": e.target.value }));
          }}
        />
        <div>
          <p>
            <span>*</span> indicates a required field
          </p>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <input type="submit" value="Submit" />
        )}
      </form>
    </ContactWrapper>
  );
}

export default Contact;
