import React from "react";
import { logo } from "../assets";

function Logo({ size = "35px" }) {
  return (
    <img
      style={{
        cursor: "pointer",
      }}
      src={logo}
      height={size}
      width={size}
      alt="logo"
    />
  );
}

export default Logo;
