import React from "react";
import styled from "styled-components";
import { breakpoints } from "../constants";
import data from "../data/prodData.json";
import { blackColor, fontSizes } from "../theme";
import Service from "./service";
import TitleHeader from "./titleHeader";
import {motion } from "framer-motion"

const OurServicesWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  gap: 10px;

  .spacer {
    height: 30px;
  }

  h2 {
    font-size: ${fontSizes.desktop.h2};
    font-weight: 600;
    color: ${blackColor};

    @media only screen and (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mobile.h2};
    }
  }

  div.wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    flex: 1 0 25%;
    /* width: 1%; */
  }
`;

function OurServices() {
  const servicesData = data.services;
  return (
    <OurServicesWrapper id="#services">
      <div className="spacer"></div>
      <TitleHeader title={"Our Sevices"} />
      <h2>What We Do</h2>
      <motion.div initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}} className="wrapper">
        {servicesData.map((data) => (
          <div id={data.index}>
            <Service {...data} />
          </div>
        ))}
      </motion.div>
    </OurServicesWrapper>
  );
}

export default OurServices;
