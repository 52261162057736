import React from "react";
import styled from "styled-components";
import { fontSizes, orangeColor } from "../theme";

const SendEmailWrapper = styled.div`
  background-color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 35px 12px;
    border-radius: 10px;
    background-color: ${orangeColor};
    color: #fff;
    gap: 10px;
    @media only screen and (max-width: 700px) {
      flex-direction: column;
    }

    h1 {
      font-weight: 600;
      font-size: ${fontSizes.desktop.h2};
      @media only screen and (max-width: 625px) {
        font-size: ${fontSizes.tablet.h4};
        /* display: none; */
      }
    }

    form {
      display: flex;
      height: fit-content;
      width: fit-content;
      align-items: center;
      border-radius: 5px;
      background-color: #fff;
      padding: 5px 3px;

      input[type="email"] {
        outline: none;
        border: none;
        font-family: "Poppins", sans-serif;
        @media only screen and (max-width: 625px) {
          width: 70%;
        }
      }
      input[type="submit"] {
        background-color: ${orangeColor};
        border: none;
        outline: none;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        font-family: "Poppins", sans-serif;
        cursor: pointer;
      }
    }
  }
`;

function SendEmail() {
  return (
    <SendEmailWrapper>
      <div>
        <h1>Lets Stay In Touch</h1>
        <form>
          <input type="email" required placeholder="Enter your email" />
          <input type="submit" value="Subscribe Now" />
        </form>
      </div>
    </SendEmailWrapper>
  );
}

export default SendEmail;
