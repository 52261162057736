import React, { useState } from "react";
import styled from "styled-components";
import { ourProjectsImages } from "../assets";
import { breakpoints } from "../constants";
import { blackColor, fontSizes, grayColor, orangeColor } from "../theme";
import TitleHeader from "./titleHeader";
import {motion } from "framer-motion"

const CaseStudyWrapper = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  /* align-self: center; */
  /* width: 100vw; */

  h2 {
    font-size: ${fontSizes.desktop.h2};
    font-weight: 600;
    color: ${blackColor};

    @media only screen and (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSizes.tablet.h2};
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mobile.h2};
    }
  }

  ul {
    display: flex;
    list-style: none;
    column-gap: 15px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
      column-gap: 8px;
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
      column-gap: 0px;
      display: none;
    }
    li {
      color: ${grayColor};
      font-weight: 500;
      cursor: pointer;
      padding: 5px;

      &.active {
        background-color: ${orangeColor};
        padding-left: 20px;
        padding-right: 20px;
        color: #fff;
        border-radius: 5px;
      }

      @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 12px;
      }
    }
  }

  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;

    @media only screen and (max-width: 600px) {
      row-gap: 10px;
    }
    img {
      max-width: 350px;
      @media only screen and (max-width: 600px) {
        border-radius: 10px;
        max-width: 85%;
      }
      /* 
      @media only screen and (max-width: ${breakpoints.mobile}) {
        border-radius: 10px;
      } */
    }
  }
`;

function CaseStudy() {
  const images = [
    ourProjectsImages.img0,
    ourProjectsImages.img1,
    ourProjectsImages.img2,
    ourProjectsImages.img3,
    ourProjectsImages.img4,
    ourProjectsImages.img5,
    ourProjectsImages.img6,
    ourProjectsImages.img7,
    ourProjectsImages.img8
  ];
  const switcherData = [
    "All",
    "UX Design",
    "Web Design",
    "App Development",
    "Game Design",
    "Graphic Design",
  ];

  const [currentTab, setCurrentTab] = useState(0);

  return (
    <CaseStudyWrapper>
      <TitleHeader title={"Our Case Study"} />
      <h2>Our Recent Project</h2>
      <ul>
        {switcherData.map((data, index) => (
          <li
            className={currentTab === index ? "active" : ""}
            onClick={() => setCurrentTab((prev) => index)}
          >
            {data}
          </li>
        ))}
      </ul>
      <div>
      {images.map((image, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.5 }}
          viewport={{ once: true,amount: 0.5 }}
        > <img src={image}alt=""/>
        </motion.div>
      ))}
      </div>
    </CaseStudyWrapper>
  );
}

export default CaseStudy;
