import { Icon } from "@iconify/react";
import React from "react";
import styled from "styled-components";
import { footerBackground } from "../assets";
import { breakpoints } from "../constants";
import { blackColor, fontSizes, orangeColor } from "../theme";
import Logo from "./logo";

const FooterWrapper = styled.footer`
  background-image: url(${footerBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgba(0, 0, 0, 0.95); */
  /* background-blend-mode: color-burn; */
  /* height: 100vh; */
  color: #fff;

  padding: 40px 30px 10px 30px;

  display: flex;
  flex-direction: column;

  div.top-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .group {
      width: 20%;

      @media only screen and (max-width: 869px) {
        width: 100%;
        gap: 20px;
        text-align: center;
      }

      h5 {
        color: ${orangeColor};
        font-weight: 600;
        font-size: ${fontSizes.desktop.h5};

        @media only screen and (max-width: ${breakpoints.tablet}) {
          font-size: ${fontSizes.tablet.h5};
        }
        @media only screen and (max-width: ${breakpoints.mobile}) {
          font-size: ${fontSizes.mobile.h5};
        }
      }

      p {
        font-weight: 400;
        font-size: ${fontSizes.desktop.h6};

        /* @media only screen and (max-width: 869px) {
          display: flex;
          max-width: 70%;
          justify-items: center;
        } */
      }

      div {
        display: flex;
        gap: 15px;

        @media only screen and (max-width: 869px) {
          justify-content: center;
        }

        .icon-wrapper {
          background-color: #fff;
          border-radius: 50%;
          padding: 6px;
          cursor: pointer;
        }
      }
    }
  }

  span.divider {
    /* width: calc(100% - 20px); */
    background-color: #fff;
    height: 2px;
    margin: 20px 0px;
  }

  div.down-section {
    display: flex;
    font-size: ${fontSizes.desktop.h6};
    gap: 50px;

    @media only screen and (max-width: 869px) {
      flex-direction: column-reverse;
      font-size: ${fontSizes.tablet.h6};
      gap: 10px;
    }

    .copyright-group {
      display: flex;
      gap: 10px;
    }

    .privacy {
      ul {
        display: flex;
        gap: 30px;
        li {
          display: flex;
          align-items: center;
          gap: 5px;
          div.circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: ${orangeColor};
          }
          list-style-type: none;
        }
      }
    }
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <div className="top-section">
        <div className="group">
          <Logo size="80px" />
          <p>
            Global Reach. Personal Touch. Regularly.
          </p>
        </div>
        <div className="group">
          <h5>Location</h5>
          <p>3517 W. Gray St. Utica, Pennsylvania 57867</p>
        </div>
        <div className="group">
          <h5>Call Us</h5>
          <p>+19314448695</p>
          <p>info@visoncloudit.com</p>
        </div>
        <div className="group">
          <h5>Follow Us</h5>
          <div>
            <div className="icon-wrapper">
              <Icon
                icon="typcn:social-facebook"
                height={28}
                width={28}
                color={orangeColor}
              />
            </div>
            <div className="icon-wrapper">
              <Icon
                height={28}
                width={28}
                icon="bxl:instagram-alt"
                color={blackColor}
              />
            </div>
            <div className="icon-wrapper">
              <Icon
                height={28}
                width={28}
                icon="mdi:skype"
                color={blackColor}
              />
            </div>
            <div className="icon-wrapper">
              <Icon
                height={28}
                width={28}
                icon="mdi:twitter"
                color={blackColor}
              />
            </div>
          </div>
        </div>
      </div>
      <span className="divider"></span>
      <div className="down-section">
        <div className="copyright-group">
          <span>&copy;</span>
          <p>Copyright 2021. All Right Reserved By Vision Cloud</p>
        </div>
        <div className="privacy">
          <ul>
            <li>
              <div className="circle"></div>
              <p>Privacy Policy</p>
            </li>
            <li>
              <div className="circle"></div>
              <p>Terms and Conditions</p>
            </li>
          </ul>
        </div>
      </div>
    </FooterWrapper>
  );
}

export default Footer;
