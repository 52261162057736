import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GetStartedPage from "./pages/getStartedPage";
import HomePage from "./pages/homePage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* <Route path="/get-started" element={<GetStartedPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
