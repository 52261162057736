import React from "react";
import {
  About,
  Contact,
  Footer,
  Home,
  Navbar,
  OurServices,
  Portfolio,
  SendEmail,
} from "../components";
import SnackbarComponent from "../components/snackbarComponent";
import { SnackbarProvider } from "../context/snackbarContext";

function HomePage() {
  return (
    <SnackbarProvider>
      <Navbar />
      <Home />
      <OurServices />
      <About />
      <Portfolio />
      <Contact />
      <SendEmail />
      <Footer />
      <SnackbarComponent />
    </SnackbarProvider>
  );
}

export default HomePage;
