import React from "react";
import styled from "styled-components";
import { analysisIcon, dataAnalytics, manIcon, targetIcon } from "../assets";
import { breakpoints, capabilitiesBackgroundImg } from "../constants";
import { blackColor, fontSizes, orangeColor } from "../theme";
import {motion } from "framer-motion"

const CapabilitiesSectionWrapper = styled.div`
  background-image: url(${capabilitiesBackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: color-burn;
  /* min-height: 100vh; */
  padding-top: 70px;
  padding-bottom: 50px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
  gap: 30px;

  div {
    display: flex;
    gap: 10px;
    align-items: baseline;
    p {
      color: ${orangeColor};
      font-weight: 400;
    }
    span {
      width: 50px;
      height: 1.5px;
      background-color: ${orangeColor};
    }
  }

  h2 {
    font-size: ${fontSizes.desktop.h2};
    font-weight: 600;
    max-width: 491px;
    /* line-height: 58.6px; */
  }
  p {
    max-width: 544px;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }
  .cards-section {
    display: flex;
    align-items: center;
    gap: 15px;
    /* margin-right: 30px; */
    flex-wrap: wrap;
  }

  .performance {
    background-color: ${orangeColor};
    /* width: 80vw; */
    margin-top: 10px;
    margin-bottom: 40px;
    margin-right: 30px;
    /* min-height: 50px; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px;
    max-width: 1000px;

    @media only screen and (min-width: ${breakpoints.desktop}) {
      /* margin-left: 60px;
      margin-right: 90px; */
    }

    span {
      background-color: #fff;
      height: 100px;
      width: 2px;
      content: "";
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 0px;
      align-items: center;
      /* @media only screen and (max-width: ${breakpoints.mobile}) {
        /* min-width: 50px; */
      /* } */

      img {
        height: 40px;
        width: 40px;
        @media only screen and (max-width: ${breakpoints.mobile}) {
          height: 30px;
          width: 30px;
        }
      }
      h3 {
        font-weight: 600;
        font-size: ${fontSizes.desktop.h4};
        @media only screen and (max-width: ${breakpoints.mobile}) {
          font-size: ${fontSizes.mobile.h4};
        }
      }
      h5 {
        font-weight: 500;
        font-size: ${fontSizes.desktop.h6};

        @media only screen and (max-width: ${breakpoints.tablet}) {
          /* display: none; */
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
`;

function CapabilitiesSection() {
  return (
    <CapabilitiesSectionWrapper>
      <motion.div initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}}>
        <p>Our Capabilities</p>
        <span></span>
      </motion.div>
      <motion.h2 initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}}>Forward Thinking Team Of Designers & Developers</motion.h2>
      <motion.p initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus ut nisi,
        amet rhoncus volutpat neque. Ipsum in aliquam eget vitae purus felis
        eget massa felis. Eu mattis in malesuada facilisi proin vel.
      </motion.p>
      <motion.div initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}} className="cards-section">
        <Card title={"Software Development"} value={80} />
        <Card title={"Graphic Design"} value={90} />
        <Card title={"Game Design"} value={85} />
        <Card title={"Web Development"} value={100} />
      </motion.div>
      <motion.div initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 ,transition:{
        type: "spring", stiffness:50,staggerChildren: 0.5,  delay:0.5     
      }}} className="performance">
        <div>
          <img src={manIcon} alt="man-icon" />
          <h3>120</h3>
          <h5>Top Experts</h5>
        </div>
        <span></span>
        <div>
          <img src={targetIcon} alt="target-icon" />
          <h3>500</h3>
          <h5>Strategies</h5>
        </div>
        <span></span>
        <div>
          <img src={analysisIcon} alt="analysis-icon" />
          <h3>347</h3>
          <h5>Great Results</h5>
        </div>
        <span></span>
        <div>
          <img src={dataAnalytics} alt="data-analytics-icon" />
          <h3>120</h3>
          <h5>High Rankings</h5>
        </div>
      </motion.div>
    </CapabilitiesSectionWrapper>
  );
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 110px;
  height: 110px;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  align-items: center;
  justify-content: center;
  gap: 0;
  /* height: 110px; */
  .value {
    width: 100%;
    font-weight: 600;
    font-size: ${fontSizes.desktop.h4};
    color: ${orangeColor};
  }

  .title {
    font-weight: 300;
    font-size: ${fontSizes.desktop.h7};
    color: ${blackColor};
    align-self: center;
  }

  /* @media only screen and (max-width: 550px) {
    width: 25%;
    /* padding: 20px; */
`;

const Card = ({ value, title }) => {
  return (
    <CardWrapper>
      <p className="value">{value}%</p>
      <p className="title">{title}</p>
    </CardWrapper>
  );
};

export default CapabilitiesSection;
