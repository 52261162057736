import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { breakpoints } from "../constants";
import { blackColor, fontSizes, orangeColor } from "../theme";
import Logo from "./logo";

const NavbarWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10%;
  position: sticky;
  top: -5px;
  z-index: 2;
  background-color: #fff;
  position: -webkit-sticky;
  box-shadow: 2px 0px 2px 3px rgba(0, 0, 0, 0.2);

  .icon {
    cursor: pointer;
  }

  nav {
    .menu-icon {
      display: none;
      cursor: pointer;

      @media only screen and (max-width: ${breakpoints.tablet}) {
        display: block;
      }
    }
    ul {
      display: flex;
      column-gap: 30px;
      list-style-type: none;

      .close-icon {
        display: none;
        cursor: pointer;
        @media only screen and (max-width: ${breakpoints.tablet}) {
          position: fixed;
          display: block;
          right: 15px;
          top: 15px;
        }
      }

      li {
        a {
          text-decoration: none;
          color: ${blackColor};
          font-weight: 500;
          font-size: ${fontSizes.desktop.h6};
          /* line-height: 20px; */
          cursor: pointer;
        }
        .active {
          color: ${orangeColor};
        }
      }
      @media only screen and (max-width: ${breakpoints.tablet}) {
        display: ${(props) => (props.navState ? "flex" : "none")};
        background-color: #fff;
        text-align: center;
        right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        top: 0;
        width: 100%;
        gap: 20px;
        height: min-content;
        position: fixed;
        flex-direction: column;
      }
    }
  }
`;

function Navbar() {
  const [navState, setNavState] = useState(false);

  const onChangeMenuState = (e) => {
    setNavState((prev) => !prev);
  };

  return (
    <NavbarWrapper navState={navState}>
      <Logo size="50px" />
      <nav>
        <Icon
          className="menu-icon"
          onClick={onChangeMenuState}
          height={20}
          icon={"eva:menu-outline"}
        />
        <ul>
          <Icon
            height={20}
            onClick={onChangeMenuState}
            className="close-icon"
            icon={"eva:close-outline"}
          />
          <li>
            <Link activeClass="active" smooth spy to="#home">
              Home
            </Link>
          </li>
          <li>
            <Link activeClass="active" smooth spy to="#services">
              Services
            </Link>
          </li>
          <li>
            <Link activeClass="active" smooth spy to="#about">
              About Us
            </Link>
          </li>
          <li>
            <Link activeClass="active" smooth spy to="#portfolio">
              Portfolio
            </Link>
          </li>
          <li>
            <Link activeClass="active" smooth spy to="#contact">
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </NavbarWrapper>
  );
}

export default Navbar;
