import React from "react";
import styled from "styled-components";
import { breakpoints } from "../constants";
import { clientReviewData } from "../data/tempData";
import { blackColor, fontSizes, orangeBackground } from "../theme";
import QuoteContainer from "./quoteContainer";
import TitleHeader from "./titleHeader";

const TestimonialWrapper = styled.div`
  background-color: ${orangeBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding-top: 80px;
  padding-bottom: 80px;
  row-gap: 10px;

  h2 {
    font-size: ${fontSizes.desktop.h2};
    font-weight: 600;
    color: ${blackColor};

    @media only screen and (max-width: ${breakpoints.tablet}) {
      font-size: ${fontSizes.tablet.h3};
    }
    @media only screen and (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSizes.mobile.h3};
    }
  }
  div.quote-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }
  .quote-switcher {
    border: 1.5px ${blackColor} solid;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    cursor: pointer;

    @media only screen and (max-width: 1174px) {
      display: none;
    }
  }
`;

function Testimonial() {
  return (
    <TestimonialWrapper>
      <TitleHeader title={"Our Testimonial"} />
      <h2>What Our Client Are Saying</h2>
      <div className="quote-section">
        <p className="quote-switcher">{"<"}</p>
        {clientReviewData.map((e, i) => (
          <div id={e.imgUrl}>
            <QuoteContainer {...e} />
          </div>
        ))}
        <p className="quote-switcher">{">"}</p>
      </div>
    </TestimonialWrapper>
  );
}

export default Testimonial;
