import { Icon } from "@iconify/react";
import React from "react";
import styled from "styled-components";
import { doubleQuoteIcon } from "../assets";
import { fontSizes, grayColor, orangeColor } from "../theme";

const QuoteWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  blockquote {
    max-width: 300px;
    font-weight: 400;
    max-lines: 4;
    color: ${grayColor};
  }

  div.user-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    div.left-side {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      div {
        display: flex;
        flex-direction: column;
        h4 {
          color: ${orangeColor};
          font-weight: 500;
          font-size: ${fontSizes.desktop.h5};
        }
        p {
          color: ${grayColor};
          font-size: ${fontSizes.desktop.h7};
          font-weight: 300;
        }
      }
    }
    img.user-image {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  div.ratings-section {
    display: flex;
    column-gap: 5px;
  }
`;

function QuoteContainer({ name, quote, imgUrl, aboutPerson, ratings }) {
  const hasHalf = ratings % 1 !== 0;
  const stars = Math.trunc(ratings);

  return (
    <QuoteWrapper>
      <div className="ratings-section">
        {[...Array(stars)].map((_, i) => (
          <Icon key={i} icon={"material-symbols:star"} color={orangeColor} />
        ))}
        {hasHalf ? (
          <Icon icon={"material-symbols:star-half"} color={orangeColor} />
        ) : null}
      </div>
      <blockquote>{quote}</blockquote>
      <div className="user-section">
        <div className="left-side">
          <img className="user-image" src={imgUrl} alt="user" />
          <div>
            <h4>{name}</h4>
            <p>{aboutPerson}</p>
          </div>
        </div>
        <img src={doubleQuoteIcon} alt="double-quote-icon" />
      </div>
    </QuoteWrapper>
  );
}

export default QuoteContainer;
