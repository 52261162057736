import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { breakpoints, homeBackgroundImg } from "../constants";
import data from "../data/prodData.json";
import { fontSizes, orangeColor, whiteColor } from "../theme";
import {motion } from "framer-motion"

const HomeWrapper = styled.section`
  background-image: url(${homeBackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: color-burn;
  height: calc(100vh - 65px);
  /* display: flex;
  flex-direction: column;
  justify-content: center; */

  /* spacer {
    height: 50px;
  } */

  div {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: fit-content;
    /* align-items: center; */
    flex-direction: column;
    padding-left: 40px;
    height: inherit;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      gap: 30px;
      padding-left: 0px 10px 0 10px;
      align-items: center;
    }

    h1 {
      padding-top: 40px;
      /* width: 10em; */
      color: ${whiteColor};
      font-weight: 700;
      font-size: ${fontSizes.desktop.h1};
      @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: ${fontSizes.mobile.h1};
        width: 100%;
      }

      @media only screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fontSizes.mobile.h1};
        width: 40vw;
      }
    }

    p {
      /* width: 10em; */
      color: ${whiteColor};
      font-weight: 400;
      font-size: ${fontSizes.desktop.h6};
      @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        font-size: ${fontSizes.mobile.h6};
      }

      @media only screen and (min-width: ${breakpoints.tablet}) {
        font-size: ${fontSizes.mobile.h6};
        width: 45vw;
      }
    }

    button {
      width: fit-content;
      outline: none;
      border: none;
      background-color: ${orangeColor};
      color: #fff;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: ${fontSizes.desktop.h7};
      font-weight: 500;
      /* margin-bottom: 50px; */
    }
  }
`;

function Home() {
  const { title, description } = data.home;
  return (
    <HomeWrapper id="#home">
      <motion.div initial={{ opacity: 0, scale: 0.5 }} whileInView={{ opacity:1, scale:1 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 1 }}>
        <h1>{title}</h1>
        <p>{description}</p>
        <Link to={"/"}>
          <button>Get Start Now</button>
        </Link>
      </motion.div>
    </HomeWrapper>
  );
}

export default Home;
