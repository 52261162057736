import { Icon } from "@iconify/react";
import React from "react";
import styled from "styled-components";
import { aboutUsImg, breakpoints } from "../constants";
import { aboutUsData } from "../data/tempData";
import { fontSizes, orangeColor } from "../theme";
import {motion } from "framer-motion"

const AboutWrapper = styled.section`
  background-image: url(${aboutUsImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: color-burn;
  height: 100vh;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  padding-left: 30px;
  /* padding-top: 20px; */

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 15px;
  }

  div {
    margin-top: 40px;
    display: flex;
    gap: 10px;
    align-items: baseline;
    p {
      color: ${orangeColor};
      font-weight: 400;
    }
    span {
      width: 50px;
      height: 1.5px;
      background-color: ${orangeColor};
    }
  }

  h2 {
    font-size: ${fontSizes.desktop.h2};
    font-weight: 600;
    max-width: 491px;
    /* line-height: 58.6px; */
  }
  p {
    max-width: 544px;
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 15px;
      p {
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
`;

function About() {
  return (
    <AboutWrapper id="#about">
      <div>
        <p>About Us</p>
        <span></span>
      </div>
      <h2>Grow Your Business With Our Agency</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis arcu
        in tincidunt sed imperdiet etiam fames. Tincidunt augue risus mattis
        eget sem habitant id ultrices augue. Sit rhoncus .
      </p>

      <ul>
        {aboutUsData.map((data,index) => (
          <motion.li  id={data} initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            duration: 0,
            delay: index / 2.2,
             type: "spring", stiffness:50,staggerChildren: 0.5,
          }}
          key={index}>
            <Icon
              height={24}
              width={24}
              color={orangeColor}
              icon={"material-symbols:check-circle-rounded"}
            />
            <p>{data}</p>
          </motion.li>
        ))}
      </ul>
    </AboutWrapper>
  );
}

export default About;
