import analysisIcon from "./icons/analysis.png";
import dataAnalytics from "./icons/data-analytics.png";
import designIcon from "./icons/design.png";
import digitalMarketingIcon from "./icons/digital-marketing.png";
import doubleQuoteIcon from "./icons/double-quotes-l.png";
import gamingIcon from "./icons/gaming.png";
import logo from "./icons/logo.jpeg";
import manIcon from "./icons/man.png";
import mobileDevelopmentIcon from "./icons/mobile-development.png";
import targetIcon from "./icons/target.png";
import uxInterfaceIcon from "./icons/ux-interface.png";
import webDesignIcon from "./icons/web-design.png";
import img0 from "./images/Image-0.jpg";
import img1 from "./images/Image-1.jpg";
import img2 from "./images/Image-2.jpg";
import img3 from "./images/Image-3.jpg";
import img4 from "./images/Image-4.jpg";
import img5 from "./images/Image-5.jpg";
import img6 from "./images/Image-6.jpg";
import img7 from "./images/Image-7.jpg";
import img8 from "./images/Image-8.jpg";
import footerBackground from "./images/Image.jpg";

const ourProjectsImages = {
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
};

export {
  designIcon,
  logo,
  digitalMarketingIcon,
  gamingIcon,
  mobileDevelopmentIcon,
  uxInterfaceIcon,
  webDesignIcon,
  doubleQuoteIcon,
  analysisIcon,
  dataAnalytics,
  manIcon,
  targetIcon,
  footerBackground,
  ourProjectsImages,
};
