import {
  designIcon,
  digitalMarketingIcon,
  gamingIcon,
  mobileDevelopmentIcon,
  webDesignIcon,
} from "../assets";

const services = [
  {
    title: "UX Design",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 1,
    icon: designIcon,
  },
  {
    title: "Game Design",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 2,
    icon: gamingIcon,
  },
  {
    title: "Graphic Design",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 3,
    icon: designIcon,
  },
  {
    title: "Web Design",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 4,
    icon: webDesignIcon,
  },
  {
    title: "App Development",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 5,
    icon: mobileDevelopmentIcon,
  },
  {
    title: "Digital Marketing",
    subTitle: "Mobile App, Website",
    description:
      "Lorem ipsum dolor sit amet, consec tet ur adipiscing elit. Accumsan sed faucibu s faucibus augue. Cras ut.",
    index: 6,
    icon: digitalMarketingIcon,
  },
];

const aboutUsData = [
  "Innovative website design",
  "UI/UX design with global trends",
  "Web and email hosting service",
];

const clientReviewData = [
  {
    ratings: 4.5,
    quote:
      "Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.",
    name: "Phillip Levin",
    aboutPerson: "CEO / Creative IT",
    imgUrl:
      "https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
  {
    ratings: 4.5,
    quote:
      "Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.",
    name: "Lincoln Lipshutz",
    aboutPerson: "CEO / Creative IT",
    imgUrl:
      "https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
  {
    ratings: 4.5,
    quote:
      "Lorem ipsum dolor sit amet, consecte tur adipiscing elit. Ultrices blandit pelle ntesque nibh arcu elementum odio justo. Rhoncus.",
    name: "Craig Siphron",
    aboutPerson: "CEO / Creative IT",
    imgUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
  },
];

export { services, aboutUsData, clientReviewData };
